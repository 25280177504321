import BaseModel from '@/shared/record_store/base_model';
import AppConfig from '@/shared/services/app_config';

export default class CongressRegisteredModel extends BaseModel {
    static singular = 'congressRegisteredMember';
    static plural = 'congressRegisteredMembers';
    static indices = ['userId', 'groupId'];
    static uniqueIndices = ['id'];

    defaultValues() {
        return {
            userId: null,
            groupId: null,
        };
    }

    relationships() {
        this.belongsTo('group');
        this.belongsTo('user');
    }

};
