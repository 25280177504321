<script lang="js">
import AppConfig from '@/shared/services/app_config';
import { format, formatDistance, parse, startOfHour, isValid, addHours, isAfter } from 'date-fns';
import { hoursOfDay, exact, timeFormat} from '@/shared/helpers/format_time';
import { mdiClockOutline, mdiCalendar } from '@mdi/js'

export default {
  props: {
    poll: Object
  },

  data() {
    return {
      mdiCalendar,
      mdiClockOutline,
      showingHour: format(this.poll.whenToShowPoll || startOfHour(new Date()), 'HH:mm'),
      showingDate: format(this.poll.whenToShowPoll || new Date(), 'yyyy-MM-dd'),
      dateToday: format(new Date, 'yyyy-MM-dd'),
      times: hoursOfDay(),
      timeZone: AppConfig.timeZone,
      isShowingDatePicker: false,
      validDate: () => isValid(parse(`${this.showingDate} ${this.showingHour}`, "yyyy-MM-dd HH:mm", new Date()))
    };
  },

  methods: {
    exact,
    updateShowingAt() {
      const date = parse(`${this.showingDate} ${this.showingHour}`, "yyyy-MM-dd HH:mm", new Date());
      if (isValid(date)) {
        this.poll.whenToShowPoll = date;
      }
    }
  },

  computed: {
    twelvehour() { return timeFormat() !== 'HH:mm'; },

    showingAtHint() {
      return format(this.poll.whenToShowPoll, timeFormat());
    },

    label() {
      if (!this.poll.whenToShowPoll) { return false; }
      return formatDistance(this.poll.whenToShowPoll, new Date, {addSuffix: true});
    }
  },


  watch: {
    'poll.whenToShowPoll'(val) {
      if (!val) { return; }
      this.showingHour = format(val, 'HH:mm');
      this.showingDate = format(val, 'yyyy-MM-dd');
    },

    showingDate(val) {
      this.updateShowingAt();
    },

    showingHour(val) { this.updateShowingAt(); }
  }
};
</script>

<template lang="pug">
div
  .poll-common-closing-at-field.my-3
    .poll-common-closing-at-field__inputs
      v-layout(wrap)
        v-flex
          v-menu(
            ref='menu'
            v-model='isShowingDatePicker'
            :close-on-content-click='false'
            offset-y
            min-width="290px"
          )
            template(v-slot:activator='{ on, attrs }')
              v-text-field(
                :disabled="!poll.whenToShowPoll"
                v-model='showingDate'
                :rules="[validDate]"
                placeholder="2000-12-30"
                v-on='on'
                v-bind="attrs"
                :prepend-icon="mdiCalendar"
              )
                template(v-slot:label)
                  span(v-if="poll.whenToShowPoll" v-t="{ path: 'common.showing_in', args: { time: label } }", :title="exact(poll.whenToShowPoll)")
                  span(v-if="!poll.whenToShowPoll" v-t="'poll_common_closing_at_field.no_showing_date'")
            v-date-picker.poll-common-closing-at-field__datepicker(
              :disabled="!poll.whenToShowPoll"
              v-model='showingDate'
              :min='dateToday'
              no-title
              @input="isShowingDatePicker = false")
        v-spacer
        v-select.poll-common-closing-at-field__timepicker(
          :disabled="!poll.whenToShowPoll"
          :prepend-icon="mdiClockOutline"
          v-model='showingHour'
          :label="$t('poll_meeting_time_field.showing_hour')"
          :items="times"
          :hint="twelvehour ? showingAtHint : null"
          :persistent-hint="twelvehour"
        )
    validation-errors(:subject="poll", field="whenToShowPoll")

</template>
